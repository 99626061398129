.slideshow {
  position: relative;
  overflow: hidden;
  max-width: 2000px;
  width: 100%; /* Ensures the slideshow adapts to the screen size */
  background-color: #ffffff;
  margin: 0 auto;
}





.slideshowSlider {
  display: flex;
  white-space: nowrap;
  transition: transform ease 1000ms;
}

.slideContainer {
  position: relative;
  min-width: 100%;
}

.slide {
  display: inline-block;
  width: 100%;
  height: 750px; /* Full viewport height or a custom height */
  object-fit: cover; /* Ensures the image fills the container */
  object-position: 50% 75%; /* Aligns the focus at the top, cropping from the bottom */
}

.textOverlay {
  position: absolute;
  top: 20%; /* Centers the text vertically */
  left: 0%; /* Adjust to control the horizontal position */
  transform: translateY(-50%);
  color: white;
  max-width: 100%; /* Limit the width of the text area */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for readability */
  padding: 20px;
  padding-right: 10px;
  border-radius: 4px;
  word-wrap: break-word; /* Allows words to wrap onto new lines */
  overflow-wrap: break-word; /* Ensures long words break onto the next line */
}

.textOverlay h1 {
  font-size: 3em;
  margin: 0 0 10px;
  font-family: 'DM Sans', sans-serif;
  
}

.textOverlay p {
  font-size: 1em;
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;
  margin-bottom: 0.2rem;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #afd9e7;
}

.content {
  font-size: 100px;
  font-family: 'DM Sans', sans-serif;
}


