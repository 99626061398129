

.ContainerFaq {
  margin-left: auto; /* Center the container horizontally */
  margin-right: auto; /* Center the container horizontally */
  max-width: 1500px;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 3rem;
  display: flex;
  margin-top: 3rem;
  height: fit-content;
  align-items: left; /* Center content vertically */
  justify-content: left; /* Center content horizontally */
    
  }
    
 

.PageHeaderFaq{
    margin-top: -30px;
    margin-left: 10px;
    width: 250px; /* Set the width of the slim box */
    height: 50px; /* Set the height of the slim box */
    background-color: #023e48; /* Set the background color */
    border: 1px solid #023e48; /* Set the border style */
    border-radius: 5px; /* Add rounded corners */
    display: flex; /* Add display: flex; */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position:sticky;
}

.PageHeaderFaq p {
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-size: 25px;
    z-index: 2; /* Set a higher z-index to make the text render above other elements */

    
}

.SubContainerFaq{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a little bit of shadow */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */

}

.FaqTextContainer{
    width: 100%;
    height: 90%;
    float:left;
    align-items: left; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    display: flex;
    flex-direction: column;

}

.faq-item {
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    font-family: 'DM Sans', sans-serif;
  }
  
  .accordion {
    width: 100%;
    background-color: #f9f9f9;
    color: #444;
    cursor: pointer;
    padding: 18px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: background-color 0.4s;
    border-radius: 5px; 
    display: flex; 
    align-items: center; 
    justify-content: space-between;

  }
  
  .accordion:hover {
    background-color: #ddd;
  }

  .Title {
    color: #46525e;
    font-size: 35px;
    font-family: 'DM Sans', sans-serif;
    padding: 2rem;
    display: flex; 
    align-items: center; 
    justify-content: center; 

  }
  
  .panel {
    padding: 0 18px;
    display: block; 
    background-color: #ffffff;
    font-family: 'DM Sans', sans-serif;
    overflow: hidden;
    border-radius: 5px; 
    
  }
  
  .panel p {
    font-size: 15px;
    padding: 1rem;
    margin: 0;
  }
  
  .fa-chevron-down,
  .fa-chevron-up {
    float: right;
  }






