.BookingContainer{
    margin-left: auto; /* Center the container horizontally */
    margin-right: auto; /* Center the container horizontally */
    max-width: 1500px;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    display: flex;
    margin-top: 3rem;
    height:fit-content;
    align-items: left; /* Center content vertically */
    justify-content:left; /* Center content horizontally */
    flex-wrap: wrap;
}


.PageHeaderBooking{
    margin-top: -30px;
    margin-left: 10px;
    width: 250px; /* Set the width of the slim box */
    height: 50px; /* Set the height of the slim box */
    background-color: #023e48; /* Set the background color */
    border: 1px solid #023e48; /* Set the border style */
    border-radius: 5px; /* Add rounded corners */
    display: flex; /* Add display: flex; */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position:sticky;
}


.BookingContainer p {
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-size: 25px;
    z-index: 2; /* Set a higher z-index to make the text render above other elements */
}

.TwoContainer{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a little bit of shadow */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    
}





.DestiationContainer{
    width: 50%;
    height: 90%;
    float:left;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    display:flex;
    flex-direction: column; /* Stack items vertically */
    gap: 25px; /* Add spacing of 10 pixels between items */   
    margin-top: 5rem;
}


.MapContainer{
    width: 50%;
    height: 90%;
    float:right;
    border-radius: 10px;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    
}

.map{
    width: 96%;
    height: 800px; /* Or any desired height */
    border-radius: 10px;
    margin-bottom: 2rem;
    margin-right: 1rem;
}

.DestiationContainer p {
    color: #46525e;
    font-size: 20px;
}

.MapContainer p {
    color: #46525e;
    font-size: 30px;
    align-items: center;
}



.Button{
    font-family: 'DM Sans', sans-serif;
    margin-left: auto; /* Center the button horizontally */
    margin-right: auto; /* Center the button horizontally */
}
.ResultBox {
    margin: 1rem;
    padding: 1rem;
    width: 80%; /* Adjust based on your layout needs */
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: space-between; /* Space between the infos and the button */
    display: flex;
    flex-direction: row; /* Align children in a row */
    margin-bottom: 2rem;
    max-width: 300px;
}

.ResultInfo p {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    /* Removed margin-left and margin-right to allow text to align to the left */
}



.CreateReservation  {
    font-family: 'DM Sans', sans-serif;
    width: 200px; /* Set the width to make it wider */
    height: 40px; /* Set the height to make it taller */
}


/* Target the Google Autocomplete dropdown container */
.pac-container {
    font-size: 18px; /* Adjust font size */
    padding: 10px; /* Adjust padding for the dropdown items */
    width:30% !important; /* Make sure the dropdown matches the input width */
    max-width: none !important; /* Remove any maximum width constraints */
    box-sizing: border-box; /* Include padding in width/height */
    border-radius: 8px; /* Round the corners */
  }
  
  /* Target individual dropdown items */
  .pac-item {
    font-size: 18px; /* Increase the font size for readability */
    padding: 10px; /* Increase padding for more clickable area */
  }
  
  /* Target the input field within the dropdown */
  .pac-item-query {
    font-size: 18px; /* Increase font size of the query text */
  }
  
  /* Target the address icon inside dropdown items */
  .pac-icon {
    width: 20px; /* Adjust icon size */
    height: 20px; /* Adjust icon size */
    margin-right: 10px; /* Space between icon and text */
  }


  
  

@media (max-width: 1200px) {
    .BookingContainer {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center elements horizontally */
  
    }

    .map{
        width: 100%;

    }

    .DestiationContainerHeader p{
        font-family: 'DM Sans', sans-serif;
        font-size: 100px;
    }


    .PageHeaderBooking p {
        font-family: 'DM Sans', sans-serif;
        font-size: 1.5rem;
    }


    .MapContainer{
        margin-bottom: 0rem;
        width:100%;
        padding: 1rem;
        float: none; 
    }

    .DestiationContainer {
        width: 100%; /* Set both text containers to full width */
        float: none; /* Remove float to stack the text containers */
        margin-bottom: 5rem;
    }
    .MapContainer p, .MapContainer p {
        margin: 2rem; /* Add some margin between paragraphs for better spacing */
    }

    .ResultBox {
        margin: 1rem;
        padding: 1rem;
        width: 80%; /* Adjust based on your layout needs */
        border: 2px solid #a5a5a5;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        align-items: center;
        justify-content: space-between; /* Space between the infos and the button */
        display: flex;
        flex-direction:column; /* Align children in a row */
        margin-bottom: 1rem;
        max-width: 25rem;
    }
    
    .ResultInfo p {
        font-family: 'DM Sans', sans-serif;
        font-size: 30px;
        /* Removed margin-left and margin-right to allow text to align to the left */
    }


    .pac-container {
        font-size: 20px; /* Slightly smaller font size for mobile */
        padding: 8px; /* Adjust padding for smaller touch areas */
        width: 65% !important; /* Wider dropdown on mobile */
        max-width: none !important;
        box-sizing: border-box;
    }
    
    .pac-item {
        font-size: 40px; /* Slightly smaller font size */
        padding: 30px; /* Adjust padding for more clickable area */
    }
    
    .pac-item-query {
        font-size: 40px;
        margin-right: 8px;  /* Slightly smaller font size */
    }
    
    .pac-icon {
        width: 20px; /* Slightly smaller icon size */
        height: 20px; /* Slightly smaller icon size */
        margin-right: 8px; /* Space between icon and text */
    }

    .p-datepicker-calendar-container {
        padding: 10px !important; /* Adjust padding value to your preference */
        background-color: white;  /* Optional: Add background color if needed */
        border-radius: 10px;       /* Optional: Add rounded corners to the calendar */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
      }

    .p-datepicker-calendar { /* Increase font size for all text within the calendar */
        width: 100% !important;     /* Ensure the calendar takes up the full width */
      }
      
      /* Increase the size of the table header (day names, etc.) */
      .p-datepicker-calendar thead th {
        font-size: 35px !important; /* Make header text (like day names) larger */
        padding: 10px !important;   /* Add some padding for better touch interaction */
      }
      
      /* Increase the size of the table body (day numbers) */
      .p-datepicker-calendar tbody td {
        font-size: 32px !important; /* Make day numbers larger */
        padding: 20px !important;   /* Increase padding for more space around numbers */
        height: 80px !important;    /* Adjust height of each date cell */
        width: 80px !important;     /* Adjust width of each date cell */
      }

      .p-datepicker-calendar tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .p-datepicker .p-datepicker-header .p-datepicker-title button {
        font-size: 40px !important;
        padding: 30px !important; /* optional */
      }

      .p-datepicker-next  svg {
  width: 50px !important; 
  height: 50px !important;
}

.p-datepicker-prev  svg {
    width: 50px !important; 
    height: 50px !important;
  }






}