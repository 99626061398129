


* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
 };


 .RequestBooking {
  height: fit-content;
 }

 @media only screen and (min-width: 1200px) {
  .shrinker {
    transform: scale(0.8);  /* Shrinks content to 80% of original size */
    transform-origin: center;  /* Shrinks content from the center */
    width: 100%;  /* Make sure it occupies the full width */
    height: fit-content; /* Adjust height to fit the content */
    margin-top: -20%; /* Optional: Adjust negative margins to remove extra white space on top */
    margin-bottom: -20%; /* Optional: Adjust negative margins to remove extra white space on bottom */
  }
}





.Range3 {
  position: relative; /* Set the container's position to 'relative' to act as a reference for absolutely positioned elements */
  float: right;
}

.Range3 img {
  position: absolute; /* Set the image's position to 'absolute' to take it out of the normal flow */ 
  /* Adjust the top position as per your requirement */
  right: 0;
  top: -3;
  z-index: -99;
}


.Range4 img {
  position: absolute; /* Set the image's position to 'absolute' to take it out of the normal flow */ 
  /* Adjust the top position as per your requirement */
  right: 0;
  top: -3;
  z-index: -99;
}


.CancelBooking {
  flex: 1;
  position: relative;
}

.RangeCancel {
  position: absolute;
  right: 0;
  bottom: -49;
}

.Footer {
  margin-top: auto;
}

.WrapperCancel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
