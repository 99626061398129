

.ContainerContact{
    margin-left: auto; /* Center the container horizontally */
    margin-right: auto; /* Center the container horizontally */
    max-width: 1500px;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    display: flex;
    margin-top: 3rem;
    height:fit-content;
    align-items: left; /* Center content vertically */
    justify-content:left; /* Center content horizontally */
    
 

}

.PageHeaderConatct{
    margin-top: -30px;
    margin-left: 10px;
    width: 250px; /* Set the width of the slim box */
    height: 50px; /* Set the height of the slim box */
    background-color: #023e48; /* Set the background color */
    border: 1px solid #023e48; /* Set the border style */
    border-radius: 5px; /* Add rounded corners */
    display: flex; /* Add display: flex; */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position:sticky;
}

.PageHeaderConatct p {
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-size: 25px;
    z-index: 2; /* Set a higher z-index to make the text render above other elements */

    
}

.ContactConatiner{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a little bit of shadow */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    margin-bottom: 4rem;

}

.Contact{
    width: 50%;
    height: 500px;
    float:left;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    display: flex; 
    flex-direction: column;
    gap: 1px;
}

.text {
    font-family: 'DM Sans', sans-serif;
    font-size: 35px;
    color: #023e48;
    margin: 0.25rem;
}

.us{
    font-family: 'DM Sans', sans-serif;
    font-size: 35px;
    color: #023e48;
    margin-left: -10rem;
    margin-top:0rem;
}

.Strich {
    height: 4px;
    width:60px;
    background-color: #023e48;
    margin-left: -10rem;
    border-radius: 5px;
}


.Info {
    width: 50%;
    height: 500px;
    float:right;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    display: flex; 
    flex-direction: column;
    gap: 1px;

}

.Info p{
    font-family: 'DM Sans', sans-serif;
    color: #023e48;
    margin: 0rem;
    margin-bottom: 1rem;
}

.Name{
    font-size: 30px;
}











@media screen and (max-width: 1400px) {
    .ContainerContact {
        flex-direction: column;
        align-items: center;

    }
    .Contact {
        width: 100%;
        float: none;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: -4rem;

    }

    .Info{
        width: 100%;
        float: none;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: -11rem;
    }

    .Name {
        margin: 2rem;

    }

    .Adress{
        margin-bottom: 1rem;
        font-size: medium;
    }
    .Contact p {
        margin: 2rem;
        margin-bottom: -1rem;
    }
    .us {
        margin-left: 0; /* Reset the margin-left to center the "us" text */
        margin-top: -100px;
    }
    .Strich {
        height: 4px;
        width:200px;
        margin-left: 0;
        margin-top: 1rem;
        margin-bottom: 5rem; /* Reset the margin-left to center the "Strich" */
    }
}






