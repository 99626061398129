

.Footer {
    margin-top: 3rem;
    height: 30px;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    background-color: #023e48 ;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;

}

hr {
    min-height: 60%;
    max-height: 100vh;
    margin:10px;
  }

.Footer p {
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-size: 0.7rem;
  }