

.ContainerAbteil{
    margin-left: auto; /* Center the container horizontally */
    margin-right: auto; /* Center the container horizontally */
    max-width: 1500px;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    display: flex;
    margin-top: 3rem;
    height:fit-content;
    align-items: left; /* Center content vertically */
    justify-content:left; /* Center content horizontally */

}

.PageHeader{
    margin-top: -30px;
    margin-left: 10px;
    width: 250px; /* Set the width of the slim box */
    height: 50px; /* Set the height of the slim box */
    background-color: #023e48; /* Set the background color */
    border: 1px solid #023e48; /* Set the border style */
    border-radius: 5px; /* Add rounded corners */
    display: flex; /* Add display: flex; */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position:sticky;
}

.PageHeader p {
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-size: 25px;
    z-index: 2; /* Set a higher z-index to make the text render above other elements */

    
}

.ok{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a little bit of shadow */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */

}

.aboutUstext1{
    width: 50%;
    height: 90%;
    float:left;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

.aboutUstext1 p {
    margin-top: 5rem;
    margin-left: 15rem;
    font-family: 'DM Sans', sans-serif;
    font-size: 30px;
    margin-right: 15rem;
    margin-bottom: 10rem;
    color: #023e48;
   

}

.aboutUstext2 {
    width: 50%;
    height: 90%;
    float: right;
    display: flex; /* Ensure it's using flexbox for alignment */
    align-items: center; /* This will center the content vertically */
    justify-content: center; /* This will center the content horizontally */
    padding: 2rem; /* Adjust padding as needed */
    margin-top: 5rem;
}

.aboutUstext2 p {
    margin-top: -50; /* Reset the top margin if not needed */
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    margin-right: 2rem; /* Adjust right margin for consistency */
    margin-left: -3rem; /* Adjust left margin for consistency */
    line-height: 1.5;
    text-align: justify;
    padding-top: 1rem; /* Add padding at the top if needed */
    padding-right: 1rem;
    padding-bottom: 2rem; /* Add padding at the bottom if needed */
}


@media screen and (max-width: 1200px) {
    .PageHeader p {
        font-size: 1.5rem; /* Increase font size for PageHeader text on mobile */
      }

    .ContainerAbteil {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center elements horizontally */
    }
    .aboutUstext1, .aboutUstext2 {

        width: 100%; /* Set both text containers to full width */
        float: none; /* Remove float to stack the text containers */
    }
    .aboutUstext1 p {
        font-size: 40px;
        margin: 2rem; /* Add some margin between paragraphs for better spacing */
        margin-bottom: -5rem;
    }

    .aboutUstext2 p {
        font-size: 35px;
        margin: 1rem; /* Add some margin between paragraphs for better spacing */
        margin-bottom: 1rem;
        padding: 1rem;
    }

    .servicesList ul{
        margin-bottom: 3rem;


    }

    .servicesList h3{
        margin-bottom: 3rem;
    }

}


.servicesList {
    width: 50%;
    height: 90%;
    float: left;
    font-family: 'DM Sans', sans-serif;
    color: #000000;
}

.servicesList h3 {
    margin-top: 2rem;
    font-size: 25px;
    line-height: 1.5;
    text-align: justify;
    margin-right: 3rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
    margin-top: -1rem;
    font-weight: normal;
    color: #023e48;
}

.servicesList ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 6rem;
}

.servicesList li {
    font-size: 20px;
    line-height: 1.5;
    text-align: justify;
    display: flex; /* Add flex display */
    align-items: center; /* Center align items vertically */
    margin-bottom: 1rem;
}

.servicesList li svg {
    margin-right: 0.5rem; /* Adjust margin between icon and text */
}


