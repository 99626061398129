

.ContainerGeneral{
    margin-left: auto; /* Center the container horizontally */
    margin-right: auto; /* Center the container horizontally */
    max-width: 1500px;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 3rem;
    display: flex;
    margin-top: 3rem;
    height:fit-content;
    align-items: left; /* Center content vertically */
    justify-content:left; /* Center content horizontally */

}

.GeneralHeader{
    margin-top: -30px;
    margin-left: 10px;
    width: 300px; /* Set the width of the slim box */
    height: 50px; /* Set the height of the slim box */
    background-color: #023e48; /* Set the background color */
    border: 1px solid #023e48; /* Set the border style */
    border-radius: 5px; /* Add rounded corners */
    display: flex; /* Add display: flex; */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position:sticky;
}

.GeneralHeader p {
    font-family: 'DM Sans', sans-serif;
    color: #ffffff;
    font-size: 25px;
    z-index: 2; /* Set a higher z-index to make the text render above other elements */

    
}

.SubContainerGeneral{
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a little bit of shadow */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */

}

.text1{
    width: 50%;
    height: 90%;
    float:left;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

.text1 p {
    margin-top: 10rem;
    margin-left: 15rem;
    font-family: 'DM Sans', sans-serif;
    font-size: 30px;
    margin-right: 7rem;
    margin-bottom: 15rem;
    color: #023e48;
   

}

.text2 {
    width: 60%;
    height: 90%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center; /* Default alignment for all children */
    justify-content: center;
    padding: 2rem;
    margin-top: -12rem;
    margin-left: -10rem;
    
}

.payment {
    width: 50%;
    height: 90%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center; /* Default alignment for all children */
    justify-content: center;
    padding: 0rem;
    margin-top: 3rem;
    margin-right: 2rem;
    margin-left: 0rem; /* Adjust if necessary */
    
}




.paymentMethods {
    flex-direction: row;
    margin-top: -2rem;
}


.icons {
    flex-direction: row;
    align-items: center;
    margin-top: -1rem;
    margin-left: -3rem;
}



.text2 p {
    margin-top: 0; /* Reset the top margin if not needed */
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    margin-right: 2rem; /* Adjust right margin for consistency */
    margin-left: 2rem; /* Adjust left margin for consistency */
    line-height: 1.5;
    text-align: justify;
    padding-top: 1rem; /* Add padding at the top if needed */
    padding-bottom: 2rem; /* Add padding at the bottom if needed */
}

.text2 a {
    align-self: center; /* Centers the phone number, you can also set to flex-start to align with the icon */
    font-family: 'DM Sans', sans-serif;
    font-size: 40px;
    line-height: 1.5;
    text-align: justify;
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-top: 15rem;
    margin-right: 2rem;
    margin-left: -3rem; /* Adjust if necessary */
    text-decoration: none;
    color: inherit;
}



.no-link-style {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits text color from the parent element */
  }


.text2{
    width: 50%;
    height: 90%;
    float:right;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
  
}


@media screen and (max-width: 1200px) {
    .ContainerAbteil {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center elements horizontally */
    }
    .text1, .text2 {
        width: 100%; /* Set both text containers to full width */
        float: none; /* Remove float to stack the text containers */
    }
    .text2 p {
        font-size: 2rem;
        margin: 1rem; /* Add some margin between paragraphs for better spacing */
        margin-top: 1rem;
    }

    .text1 p {
        font-size: 3rem;
        margin: 1rem; /* Add some margin between paragraphs for better spacing */
        margin-top: 2rem;
        margin-left: 2rem;
    }

    .GeneralHeader p {
        font-size: 1.5rem; 
    }

    .text2 a {
        align-self: center; /* Centers the phone number, you can also set to flex-start to align with the icon */
        font-family: 'DM Sans', sans-serif;
        font-size: 60px;
        line-height: 1.5;
        text-align: justify;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin-top: 15rem;
        margin-right: 2rem;
        text-decoration: none;
        color: inherit;
    }

}


